import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Icon from "react-icons-kit";
import { ic_public_outline } from 'react-icons-kit/md/ic_public_outline'
import { ic_public_twotone } from 'react-icons-kit/md/ic_public_twotone'
import { trash2 } from 'react-icons-kit/feather/trash2';

import Buttons from "./Buttons";
import { Spinner } from "react-bootstrap";
import Api from "../services/Api";

const QuestListing = (props) => {

    const { e, i, readmore, setReadMore, handlePublic, varient = 'public', deleteQuest, deleteids, updateSummary } = props

    console.log(e)

    const [isInputFieldActive, setInputFieldActive] = useState(false);
    const [updatedSummary, setUpdatedSummary] = useState('')

    const navigate = useNavigate();

    const handleguid = (e, data) => {
        e.stopPropagation();
        window.open(`https://www.nestheads.app/guid?id=${data}`, "_blank")
    }

    const _handlePublic = (e, first, sec) => {
        e.stopPropagation();
        handlePublic(first, sec)
    }

    const _deleteQuest = (e, chatid, characterid, index) => {
        e.stopPropagation();
        deleteQuest(chatid, characterid, index)
    }

    function secondsToHms(d) {

        const minutes = Math.floor(d / 60);
        const seconds = d - minutes * 60;

        return `${minutes > 0 ? minutes + " min" : ''}${seconds > 0 ? (minutes > 0 ? ", " : "") + seconds + " sec" : ''}`
    }

    const handleSummary = (e, val) => {
        if (varient == 'public') {
            return
        }
        e.stopPropagation()
        if (isInputFieldActive) {
            return
        }
        setUpdatedSummary(val);
        setInputFieldActive(true)
    }

    const handleCancleSummary = (e) => {
        e.stopPropagation()
        setUpdatedSummary('');
        setInputFieldActive(false)
    }

    const handleUpdateSummary = (e, chat_id, id, summary) => {
        e.stopPropagation();
        if (updatedSummary?.trim() == "" || updatedSummary?.trim() == summary?.trim()) {
            setUpdatedSummary('')
            setInputFieldActive(false)
            return
        }
        updateSummary(chat_id, id, updatedSummary);
        setUpdatedSummary('')
        setInputFieldActive(false)
    }
    return (
        <div key={i} data-aos="fade-up"  onClick={(eve) => handleguid(eve, e.guid)} className=' my-4 my-lg-5 px-3 px-lg-4 px-md-3 py-4 py-lg-5 border border-dark border-4' style={{ borderRadius: '30px', overflow: "hidden", boxShadow: "0px 5px 15px 2px #eee", cursor: 'pointer' }} >
            <div className="d-flex justify-content-between">
                <div className="d-flex align-items-center" >
                    {varient == 'public' ?
                        <>
                            <div className='mx-1 mx-sm-2'>
                                <img referrerPolicy="no-referrer" src={e?.user?.photo || require("../assets/user.png")} className='homepage_quest_header_user_img' />
                            </div>
                            <div>
                                <div className="fw-bold homepage_slider_model_seo lh-1" >{e.user.name}</div>
                                <div className='homepage_slider_model_seo'  >@{e.user.username}</div>
                            </div>
                        </>
                        :
                        <>
                            <div className='mx-1 mx-sm-2'>
                                <img referrerPolicy="no-referrer" src={e?.character[0]?.photo || require("../assets/user.png")} className='quest_header_user_img' />
                            </div>
                            <div>
                                <div className="quest_slider_model_seo lh-1 " style={{ fontFamily: 'Dimbo', overflow: 'hidden' }} >{e.character.name}</div>
                            </div>
                        </>
                    }
                </div>
                <div className="d-flex align-items-center"  >
                    <div className='mx-1 mx-sm-2'>
                        <img src={require("../assets/history.png")} className='quest_header_img' />
                    </div>
                    <div>
                        <div className="fw-bold lh-1 homepage_slider_time " >Best Time: {secondsToHms(e.character[0].best_time)}</div>
                        <a href={`${"/models/" + e.character[0].seo}`} className='homepage_slider_model_seo' style={{ cursor: 'pointer' }} onClick={(ele) => { ele.preventDefault(); ele.stopPropagation(); navigate('/models/' + e.character[0].seo) }}>@{e.character[0].seo}</a>
                    </div>
                </div>
            </div>
            {
                e?.tags?.length > 0 &&
                <div className="quest_tags_list">
                    {e.tags.map((e, i) => <img key={i} src={e.image} alt="quest" />)}
                </div>
            }
            <div className="" style={{ marginTop: e.tags.length > 0 ? "0px" : '20px' }}>
                {e?.summary &&
                    <div onClick={(ele) => handleSummary(ele, e?.summary)} title={isInputFieldActive ? " " : "click to edit"}>
                        {!isInputFieldActive ?
                            (e.summary.length > 500 && !readmore.includes(e.id)) ?
                                <div className="fs-6" >
                                    {e.summary.slice(0, 460)}<span onClick={(ele) => { ele.stopPropagation(); setReadMore([...readmore, e.id]) }} style={{ color: 'gray', cursor: 'pointer' }}> Read more...</span>
                                </div>
                                : <div className="fs-6" >{e.summary}{e.summary.length > 500 && <span onClick={(ele) => { ele.stopPropagation(); setReadMore(prev => prev.filter((ele) => ele != e.id)) }} style={{ color: 'gray', cursor: 'pointer' }}> show less.</span>}</div>
                            :
                            <textarea onChange={(e) => setUpdatedSummary(e.target.value)} value={updatedSummary} style={{ width: '100%', height: '100px' }}></textarea>
                        }

                    </div>}
                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: '10px', alignItems: 'center' }}>
                    {varient == "public" &&
                        <div style={{ width: 'fit-content', margin: 'auto', marginTop: '20px' }}>
                            <Buttons title={"Play Now"} onClick={(eve) => handleguid(eve, e.guid)} />
                        </div>
                    }
                    {(varient != 'public') &&
                        <>
                            {isInputFieldActive ?
                                <div style={{ width: 'fit-content', margin: 'auto', marginTop: '20px', display: 'flex' }}>
                                    <div style={{ marginRight: '10px' }} >
                                        <Buttons title={"cancle"} onClick={handleCancleSummary} variant="transparent" />
                                    </div>
                                    <Buttons title={"Save"} onClick={(ele) => handleUpdateSummary(ele, e.chat_id, e.character.id, e.summary)} />
                                </div>
                                :
                                <>
                                    <div style={{ width: '70px' }}>
                                    </div>
                                    <div style={{ width: 'fit-content', margin: 'auto' }}>
                                        <Buttons title={"Play Now"} onClick={(eve) => handleguid(eve, e.guid)} />
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <div title={e.visible ? "make chat private" : "make chat public"} onClick={(eve) => _handlePublic(eve, e.chat_id, e.visible == 0 ? true : false)} style={{ cursor: 'pointer', marginLeft: '10px' }} >
                                            <Icon icon={e.visible == 1 ? ic_public_twotone : ic_public_outline} className="quest_icons" />
                                        </div>
                                        <div onClick={(ele) => _deleteQuest(ele, e.chat_id, e.character.id, i)} style={{ cursor: 'pointer', marginLeft: '10px' }} >
                                            {deleteids == i ? <Spinner size="sm" /> : < Icon icon={trash2} className="quest_icons" />}
                                        </div>
                                    </div>
                                </>
                            }
                        </>
                    }
                </div>
            </div>
        </div >
    )
}

export default QuestListing