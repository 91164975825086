import React, { useEffect, useState, useRef } from "react";
import Icon from "react-icons-kit";
import { arrowLeft } from 'react-icons-kit/icomoon/arrowLeft'
import { arrowRight } from 'react-icons-kit/icomoon/arrowRight'
import Slider from "react-slick";
import Switch from "./Switch";


const CustomSliderButtons = ({ onClickPrevious, onClickNext, slideIndex, slidesCount }) => {
    return (
        <div className="custom-slider-buttons character_slider_button_svg">
            <div>
                <button className="custom-prev-button" onClick={onClickPrevious}>
                    <Icon icon={arrowLeft} size={12} />
                </button>
            </div>
            <div>
                <button className="custom-next-button" onClick={onClickNext}>
                    <Icon icon={arrowRight} size={12} />
                </button>
            </div>
        </div>
    )
};

const CharacterSlider = (props) => {
    const { characterCategory, onchangeSlides = ()=>{} } = props
    const [slideIndex, setSlideIndex] = useState(0);
    const [slidesCount, setSlidesCount] = useState(0);
    const [reloading, setReloading] = useState(false);
    const [autoPlay, setAutoPlay] = useState(true);

    const [items, setItems] = useState([]);
    const sliderRef = useRef();

    const goToPrevious = () => {
        sliderRef.current.slickPrev();
    };

    const goToNext = () => {
        sliderRef.current.slickNext();
    };

    let _settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: autoPlay,
        autoplaySpeed: 4000,
        pauseOnHover: false,
        dots: false,
        arrows: false,
        swipe: false, // Disable swipe
        draggable: false,
        beforeChange: (current, next) => {
            setSlideIndex(next);
            // afterChange(current, next)
        },
        afterChange: (index) => {
            if (index + 1 == items.length && props.onGoNext) {
                setTimeout(() => props.onGoNext(), 4000);
            }
        },
    };

    const handle_autoPlay = () => {
        if (autoPlay) {
            sliderRef.current.slickPause()
            setAutoPlay(false)
        }
        else {
            sliderRef.current.slickPlay()
            setAutoPlay(true)
        }
    }

    useEffect(() => {
        if (characterCategory?.default_character?.dialogue?.length) {
            setItems(characterCategory?.default_character?.dialogue);
            setSlidesCount(characterCategory?.default_character?.dialogue?.length);
            setSlideIndex(0)
            if(characterCategory?.default_character?.dialogue?.length == 1){
                setTimeout(() => props.onGoNext(), 4000);
            }
        } else {
            setItems(null);
            if (props.onGoNext)
                setTimeout(() => props.onGoNext(), 1);
            setSlidesCount(0);
            setSlideIndex(0);

        }
    }, [characterCategory])

    return (
        <div className="slider-wrapper">
            {!reloading &&
                items?.length > 1 ?
                <Slider {..._settings} ref={sliderRef} >
                    {items?.map((item , i) => {
                        return (
                            <div key={i} >
                                <div className="main_character_details_container" style={{ backgroundColor: characterCategory?.default_character?.color || 'gray' }} >
                                    <div className="character_details" style={{ position: 'relative' }} >
                                        <div className="custom-slider-count-component" >{slideIndex + 1 + "/" + slidesCount}</div>
                                        <div style={{ width: '150px', height: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '50%', overflow: 'hidden', backgroundColor: characterCategory?.default_character?.color }} >
                                            {/* <img src={selectCharacterType == 't' ? characterCategory?.default_character?.toxic_photo : characterCategory?.default_character?.healthy_photo?.[0]} style={{ width: '100px', height: '100px', objectFit: 'contain' }} /> */}
                                            <img src={characterCategory?.default_character?.image} style={{ width: '100px', height: '100px', objectFit: 'contain' }} />
                                        </div>
                                        <div className="right_container"  >
                                            <div className="toggle_container">
                                                <div style={{ fontSize: "25px" }} >{characterCategory?.default_character?.name}</div>
                                            </div>
                                            <div>
                                                <div>{item.designation}</div>
                                                <div style={{ fontSize: "25px", overflow: 'hidden', lineHeight: '30px' }} >{item.description}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </Slider >
                :
                (items?.[0] && <div>
                    <div className="main_character_details_container" style={{ backgroundColor: characterCategory?.default_character?.color || 'gray' }} >
                        <div className="character_details" style={{ position: 'relative' }} >
                            <div className="custom-slider-count-component" >{slideIndex + 1 + "/" + slidesCount}</div>
                            <div style={{ width: '150px', height: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '50%', overflow: 'hidden', backgroundColor: characterCategory?.default_character?.color }} >
                                {/* <img src={selectCharacterType == 't' ? characterCategory?.default_character?.toxic_photo : characterCategory?.default_character?.healthy_photo?.[0]} style={{ width: '100px', height: '100px', objectFit: 'contain' }} /> */}
                                <img src={characterCategory?.default_character?.image} style={{ width: '100px', height: '100px', objectFit: 'contain' }} />
                            </div>
                            <div className="right_container"  >
                                <div className="toggle_container">
                                    <div style={{ fontSize: "25px" }} >{characterCategory?.default_character?.name}</div>
                                </div>
                                <div>
                                    <div>{items?.[0].designation}</div>
                                    <div style={{ fontSize: "25px", overflow: 'hidden', lineHeight: '30px' }} >{items?.[0].description}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>)
            }
            {
                items?.length > 1 && <CustomSliderButtons
                    onClickPrevious={goToPrevious}
                    onClickNext={goToNext}
                    slideIndex={slideIndex}
                    slidesCount={slidesCount}
                />
            }
            {items?.length > 1 && <div className="custom-slider-count-component" ><Switch isOn={autoPlay} setSwitch={handle_autoPlay} outterStyle={{ backgroundColor: autoPlay ? '#000' : "gray" }} innerStyle={{ backgroundColor: '#fff' }} autoplay={true} /></div>}
        </div >
    )
}

export default CharacterSlider