import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Api from '../services/Api';
import { Alert } from 'react-bootstrap';
import ChatComponent from '../components/ChatComponent';
import { Helmet } from 'react-helmet';
import ChatListing from '../components/ChatListing';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import MyNestheads from '../components/MyNestheads';
import { useDispatch } from 'react-redux';
import { setChatdata } from '../actions/chatActions';
import Quest from '../components/Quests';
import SubCategory from '../components/SubCategory';

const Chat = (props) => {
  let location = useLocation();
  const [loading, setLoading] = useState(false);
  const [chats, setChats] = useState([]);
  const [validChats, setValidChats] = useState(false);
  const [num, setNum] = useState(0);
  const [_alert, setAlert] = useState({ varient: '', msg: '' });
  const [_helmet, setHelmet] = useState({ title: 'Nestheads - chat', des: 'description' });
  const [delate_id, setDeleteID] = useState(null);
  const [categoryTitle, setCategoryTitle] = useState('');

  const [activeCategory, setActiveCategory] = useState('');
  const [chat_id, setChat_id] = useState(0);
  const [character_id, setCharacter_id] = useState(null);

  const [activetype, setActiveType] = useState('h');
  const [selectedFilter, setSelectedFilter] = useState('new')
  const [characters, setCharacters] = useState([])

  const categories = useSelector(state => state.category.categories);
  const isLogged = useSelector((state) => state.user.isLogged)
  const defaultCharacter = useSelector(state => state.characotors.default_charactor);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // useEffect(() => {
  //   let validChats = []
  //   chatsData.map((e) => {
  //   })
  //   console.log(chatsData);
  // }, [])

  const setWidth = () => {
    let width = document.body.offsetWidth;
    // alert(width)
    if (width > 1500) {
      setNum(120)
    } else if (width > 1200) {
      setNum(90)

    } else if (width > 900) {
      setNum(60)

    } else if (width > 600) {
      setNum(55)

    } else {
      setNum(20)

    }
  }

  function formatChatDateTime(time) {
    let currentDate = new Date()
    let date = new Date(time)

    const options = {
      year: 'numeric',
      weekday: 'short',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
      timeZone: 'Asia/Kolkata'
    };

    if (
      date.getDate() === currentDate.getDate() &&
      date.getMonth() === currentDate.getMonth() &&
      date.getFullYear() === currentDate.getFullYear()
    ) {
      delete options.day;
    }

    if (
      date.getMonth() === currentDate.getMonth() &&
      date.getFullYear() === currentDate.getFullYear()
    ) {
      delete options.month;
    }

    if (date.getFullYear() === currentDate.getFullYear()) {
      delete options.year;
    }

    if (date.getDay() === currentDate.getDay()) {
      delete options.weekday;
    }

    return date.toLocaleString('en-IN', options);
  }

  window.addEventListener('load', setWidth);
  window.addEventListener('resize', setWidth)

  useEffect(() => {
    setWidth()
  }, [chats])


  const getCategories = () => {
    if (activeCategory == 'quests') return <Quest />
    else if (activeCategory.toLowerCase() == 'nestheads') return <MyNestheads categoryTitle={categoryTitle} />
    else return <SubCategory categoryTitle={categoryTitle} />
  }


  const getCharacterlist = async () => {
    setLoading(true)

    let chatres = Api.post('chat/list')
    let res = Api.post('characters/list')

    Promise.all([chatres, res])
      .then((res) => {
        if (res[0].success && res[0].data) {
          // console.log(res[1].data)
          let chatmessages = false
          res[0].data.map((resp) => {
            if (!chatmessages) {
              if (resp.chat_messages) {
                chatmessages = true
              }
            }
          })
          setValidChats(chatmessages)
          // dispatch(setChatdata(res[0].data))
          setChats(res[0].data)
        }
        if (res[1].success) {
          let default_id
          let first_id
          // console.log(res[0].data)
          res[1].data.map((e, i) => {
            if (e.default) {
              default_id = e
            }
            if (i == 0) {
              first_id = e
            }
          })

          let _validChats = false

          if (res[0]?.data) {
            res[0].data.map((e) => {
              if (e.chat_messages) {
                _validChats = true
              }
            })
          }

          if (!_validChats) {
            if (default_id) {
              setCharacter_id(default_id.id)
            } else {
              setCharacter_id(first_id.id)
            }
          }
        }
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    setCharacter_id(null)
    setChat_id(0)
    setActiveCategory('')
    if (props.type == 1) {
      let _loc = location.pathname.split("/");
      if (_loc[_loc.length - 1].trim() == '') {
        navigate(location.pathname.slice(0, location.pathname.lastIndexOf("/")))
      }
      setChats([])
      // console.log(_loc)
      if (_loc[_loc.length - 1] == 'quests') {
        setActiveCategory('quests')
      } else if (_loc[_loc.length - 1] == 'chat') {
        getCharacterlist()
      }
      else {
        if (_loc[_loc.length - 1].trim() == "") {
          if (_loc[_loc.length - 2] == "chat") {
            getCharacterlist()
          } else if (_loc[_loc.length - 2] == "quests") {
            setActiveCategory('quests')
          } else {
            setChats([])
            setCharacter_id(_loc[_loc.length - 2]);
          }
        } else {
          setChats([])
          setCharacter_id(_loc[_loc.length - 1]);
        }
      }

    } else if (props.type == 3) {
      let _loc = location.pathname.split("/");
      if (_loc[_loc.length - 1].trim() == '') {
        navigate(location.pathname.slice(0, location.pathname.lastIndexOf("/")))
      }
      if (_loc[2] == 'category') {
        if (_loc[3] && _loc[3] != "") {
          setCategoryTitle(_loc[3]);
          setActiveCategory(_loc[3].includes("-") ? _loc[3].split("-")[1].toLowerCase() : _loc[3])
        } else {
          navigate('/chat')
        }
      }
    } else {
      let _loc = location.pathname.split("/");
      if (_loc[_loc.length - 1].trim() == '') {
        navigate(location.pathname.slice(0, location.pathname.lastIndexOf("/")))
      }
      setChat_id(_loc[_loc.length - 1])
      setCharacter_id(_loc[_loc.length - 2])
    }
  }, [location])

  const deleteChat = (chat_id, character_id) => {
    setDeleteID({ chat_id, character_id })
    setAlert({ varient: "", msg: '' })
    Api.post('chat/delete', {
      chat_id,
      character_id
    }).then((res) => {
      if (res.success) {
        let _chats = [];

        chats.map((e) => {
          if (e.chat_messages == null || (e.character_id == character_id && e.chat_id == chat_id)) {
          } else {
            _chats.push(e)
          }
        })
        if (_chats.length == 0) {
          setCharacter_id(defaultCharacter.id)
          setValidChats(false)

        }
        setChats(_chats);
        dispatch(setChatdata(_chats))
        if (_chats.length == 0) {
          setAlert({ varient: "", msg: '' })
        } else {
          setAlert({ varient: 'success', msg: 'Chat deleted successfully!' })
        }
      } else {
        setAlert({ varient: 'danger', msg: 'Some error Occurred!' })
      }
    })
      .catch((err) => {
        setAlert({ varient: 'danger', msg: 'Some error Occurred!' })

      })
      .finally(() => {
        setDeleteID(null)
      })

  }
  const handleSideBarClick = (e) => {

    if (e.name == 'nestheads') {
      navigate('/chat/category/nestheads')
      return
    }
    if (e.name == 'quests') {
      navigate('/chat/category/quests')
      return
    }


    setActiveCategory(e.name.toLowerCase())
    setCategoryTitle(e.name)
    navigate('/chat/category/' + e.id + "-" + e.name.toLowerCase().replace(" ", '-'))
  }

  const handlePublic = (chat, val) => {
    Api.post('chat/set-visibility/' + chat.chat_id, { "visible": val ? 1 : 0 })
      .then((res) => {
        if (res.success) {
          let _updatesData = [];
          chats.map((e) => {
            if (e.chat_id == chat.chat_id) {
              e.visible = val
              _updatesData.push(e)
            } else {
              _updatesData.push(e)
            }
          })
          setChats(_updatesData)
        }
      })
  }

  const pagesData = () => {
    if (props.type == 1) {
      return (
        <div className='chat_page_container'>
          <Helmet>
            <title>{_helmet.title}</title>
            <meta name="description" content={_helmet.des} />
          </Helmet>
          {_alert.msg && <Alert variant={_alert.varient} dismissible onClose={() => setAlert({ varient: "", msg: "" })}>
            {_alert.msg}
          </Alert>}
          {!validChats ?
            <ChatComponent chat_id={chat_id} character_id={character_id} quests={location?.state?.quests} />
            :
            chats.map((e, i) => {
              if (e.chat_messages)
                return <ChatListing e={e} i={i} deleteChat={deleteChat} delate_id={delate_id} num={num} handlePublic={handlePublic} />
            })}
        </div>
      )
    }

    else if (props.type == 3) {
      return getCategories()
    }

    return (
      <div className='chat_page_container' >
        <ChatComponent chat_id={chat_id} character_id={character_id} />
      </div>
    );
  }

  return (
    <div style={{ display: 'flex', maxWidth: '100%' }}>
      {/****sidebar starts*** */}
      <div className='chatPage_sibebar_container'>
        <div className='chatPage_sibebar'>
          <div style={{ cursor: 'pointer', margin: "10px 0px" }} onClick={() => handleSideBarClick({ name: 'nestheads' })}>
            <div style={{ borderRadius: 2, border: activeCategory.toLowerCase() == 'nestheads' ? "2px solid #000" : "2px solid transparent", backgroundColor: activeCategory.toLowerCase() == 'nestheads' ? "rgba(146, 207, 213, 1)" : "transparent", padding: 10, display: 'flex', justifyContent: 'space-between' }} >
              <div style={{ display: 'flex' }}>
                <img src={require('../assets/map.png')} width={25} height={25} style={{ objectFit: 'contain' }} />
                <div className='chatPage_sibebar_tagname' style={{ margin: '0 10px', color: activeCategory.toLowerCase() == 'nestheads' ? "#000" : "rgba(30, 30, 30, 0.5)" }}>My NEST</div>
              </div>
            </div>
          </div>
          <div style={{ cursor: 'pointer', margin: "10px 0px" }} onClick={() => handleSideBarClick({ name: 'quests' })}>
            <div style={{ borderRadius: 2, border: activeCategory.toLowerCase() == 'quests' ? "2px solid #000" : "2px solid transparent", backgroundColor: activeCategory.toLowerCase() == 'quests' ? "rgba(146, 207, 213, 1)" : "transparent", padding: 10, display: 'flex', justifyContent: 'space-between' }} >
              <div style={{ display: 'flex' }}>
                <img src={require('../assets/quest.png')} width={25} height={25} style={{ objectFit: 'contain' }} />
                <div className='chatPage_sibebar_tagname' style={{ margin: '0 10px', color: activeCategory.toLowerCase() == 'quests' ? "#000" : "rgba(30, 30, 30, 0.5)" }}>My Quests</div>
              </div>
            </div>
          </div>
          {categories.length > 0 && categories.map((e, i) => {
            return (
              <div key={i} style={{ cursor: 'pointer', margin: "10px 0px" }} onClick={() => handleSideBarClick(e)}>
                <div style={{ borderRadius: 2, border: activeCategory.toLowerCase() == e?.name.toLowerCase() ? "2px solid #000" : "2px solid transparent", backgroundColor: activeCategory.toLowerCase() == e?.name.toLowerCase() ? "rgba(146, 207, 213, 1)" : "transparent", padding: 10, display: 'flex', justifyContent: 'space-between' }} >
                  <div style={{ display: 'flex' }}>
                    <img src={e?.image} width={25} height={25} style={{ objectFit: 'contain' }} />
                    <div className='chatPage_sibebar_tagname' style={{ margin: '0 10px', color: activeCategory.toLowerCase() == e?.name.toLowerCase() ? "#000" : "rgba(30, 30, 30, 0.5)" }}>My {e.name}</div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      {/****sidebar ends*** */}

      <div style={{ flex: 1, width: '20px' }} >
        {loading ?
          <div className='chat_page_container'>
            <div style={{ backgroundColor: 'rgb(250, 250, 250)', borderRadius: '20px', height: '200px', margin: '5px auto' }} ></div>
            <div style={{ backgroundColor: 'rgb(250, 250, 250)', borderRadius: '20px', height: '300px', margin: '5px auto' }} ></div>
          </div>
          :
          <>
            {pagesData()}
          </>
        }
      </div>
    </div >
  )
};

export default Chat